import React from 'react';

const GithubLogo = () => {
    return (
        <div className='github-logo'>
            <svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 120 120' style={{ enableBackground: 'new 0 0 120 120' }} xmlSpace='preserve'>
                <path
                    className='github-svg'
                    d='M60,1.5c-33.1,0-60,26.9-60,60c0,26.5,17.2,49,41,56.9c3,0.6,4.1-1.3,4.1-2.9c0-1.4-0.1-5.2-0.1-10.2
	c-16.7,3.6-20.2-8-20.2-8c-2.7-6.9-6.7-8.8-6.7-8.8c-5.4-3.7,0.4-3.6,0.4-3.6c6,0.4,9.2,6.2,9.2,6.2c5.4,9.2,14,6.5,17.5,5
	c0.5-3.9,2.1-6.5,3.8-8c-13.3-1.5-27.3-6.7-27.3-29.7c0-6.6,2.3-11.9,6.2-16.1c-0.6-1.5-2.7-7.6,0.6-15.9c0,0,5-1.6,16.5,6.2
	c4.8-1.3,9.9-2,15-2c5.1,0,10.2,0.7,15,2c11.5-7.8,16.5-6.2,16.5-6.2c3.3,8.3,1.2,14.4,0.6,15.9c3.8,4.2,6.2,9.6,6.2,16.1
	c0,23-14,28.1-27.4,29.6C73,89.8,75,93.5,75,99.1c0,8-0.1,14.5-0.1,16.5c0,1.6,1.1,3.5,4.1,2.9c23.8-7.9,41-30.4,41-56.9
	C120,28.3,93.1,1.5,60,1.5z'
                />
            </svg>
        </div>
    );
};

export default GithubLogo;
